<template>
    <el-dialog v-if="allotPro.show" :title="title" :visible.sync="allotPro.show">
        <el-row>
            <el-col :span="12">
                <el-card>
                    <div slot="header" class="clearfix">
                        <span>分配学员</span>
                    </div>
                    <el-row v-for="student in studentList" :key="student.index">
                        <el-col :span="8">
                            :{{ student.studentId }}
                        </el-col>
                        <el-col :span="6">
                            :{{ student.studentName }}
                        </el-col>
                        <el-col :span="8">
                            :{{ student.mobile }}
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
            <el-col :span="7" :offset=2>
                <el-card>
                    <div slot="header" class="clearfix">
                        <span>服务号</span>
                    </div>
                    <el-select v-model="chooseObj" filterable class="condition-select"
                               placeholder="请选择">
                        <el-option v-for="item in serviceList" v-if="!item.status" :key="item.servicesId" :label="item.name"
                                   :value="item.servicesId">
                        </el-option>
                    </el-select>
                </el-card>
            </el-col>
        </el-row>
        <el-row type="flex" justify="end">
            <el-col :span="3" :offset="5">
                <el-button type="primary" size="mini" @click="rightNow">立即提交</el-button>
            </el-col>
        </el-row>
    </el-dialog>
</template>

<script>
import * as serviceApi from "@/api/waiter/waiterServices"
import * as studentApi from "@/api/waiter/waiterStudent"

export default {
    name: "AllotStudent",
    props: {
        title: {
            type: String,
            require: true
        },
        allotPro: {
            type: Object,
            require: true,
            default: {
                show: false
            }
        },
        studentList: {
            type: Array,
            require: true
        },
        callback: {
            type: Function,
            default: (function () {

            })
        },
    },
    data() {
        return {
            dialogTableVisible: this.allotPro.show,
            chooseObj: "",
            serviceList: []
        };
    },
    methods: {
        findService() {
            serviceApi.findList().then(res => {
                if (res.success) {
                    this.serviceList = res.data
                }
            })
        },
        rightNow: function () {
            if (this.studentList.length < 1) {
                this.$message.warning("未选择学员")
                return
            }
            if (!this.chooseObj) {
                this.$message.warning("未选择分配的服务号")
                return
            }
            const loading = this.$loading({
                lock: true,//同v-loading的修饰符
                text: '分配中~请稍等...',//加载文案
                backgroundColor: 'rgba(55,55,55,0.4)',//背景色
                spinner: 'el-icon-loading',//加载图标
                target: document.querySelector(".el-tabs__content")//loading需要覆盖的DOM节点，默认为body
            })

            studentApi.allot(this.chooseObj, this.studentList.map(res => res.studentId)).then(res => {
                loading.close()
                this.allotPro.show = false
                if (res.success) {
                    this.allotPro = {
                        show: false
                    }
                    this.chooseObj = ""
                    this.$message.success(this.title + "成功!")
                } else {
                    this.$message.error(res.msg)
                }
                this.callback()
            })
        }
    },
    computed: {},
    mounted() {
        this.findService()
    }

}
</script>

<style scoped>

/deep/ .el-checkbox-button {
    margin: 3px;
}

</style>
